import React from "react";
import { connect } from "react-redux";
import UsersTable from "../../components/UsersTable";
import { AppState } from "../../store";

class Users extends React.Component<AppState> {
    public render() {
        return (<>
            <UsersTable />
        </>);
    }
}

const mapStateToProps = (state: any) => {
    return {
        connected: state.user.logged,
    };
};

export default connect(mapStateToProps)(Users);
