import { APP } from "../constants";
import { AppActionTypes } from "../types";

const initialState: { loading: boolean } = {
  loading: false,
};

const appReducer = (state = initialState, action: AppActionTypes): { loading: boolean } => {
  switch (action.type) {
    case APP.LOADING.START:
      return { loading: action.payload.loading };
    case APP.LOADING.STOP:
      return { loading: action.payload.loading };
    default:
      return state;
  }
};

export default appReducer;
