import { push } from "connected-react-router";
import { API_ROUTES } from "../../../config/routes";
import Request from "../../../services/Request";
import { ERROR } from "../constants";
import AppReducer from "./app";

export default {
  signup(form: any, action: any) {
    const body = { ...form };
    return (dispatch: any) => {
      dispatch(AppReducer.launchLoading());
      Request.post(API_ROUTES.REQUEST, body)
        .then((response: any) => {
          dispatch(AppReducer.stopLoading());
          dispatch(push("/thanks"));
        })
        .catch((error: any) => {
          dispatch(AppReducer.stopLoading());
          dispatch({
            type: ERROR.FORM.SET,
            payload: { form: { messages: error.response.data } },
          });
        });
    };
  },
};
