import React, { ReactElement } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Redirect, Route } from "react-router-dom";
import { LOCAL_ROUTES } from "../../../config/routes";

interface IPrivateRoute {
  exact: boolean;
  path: string;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  is_admin?: boolean;
}

const PrivateRoute = (props: IPrivateRoute): ReactElement => {

  const { is_admin } = props;

  if (true === is_admin) {
    return (<Route exact={props.exact} path={props.path} component={props.component} />);
  }

  return (<Route exact={props.exact} path={props.path} > <Redirect to={LOCAL_ROUTES.PROFILE} /> </Route>);
};

const mapStateToProps = (state: { user: {isAdmin: boolean}; }) => {
  return {
    is_admin: state.user.isAdmin,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
