import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

interface IProfilProps {
    user: any;
  }

function Profil(Props: IProfilProps) {
    const { t } = useTranslation();
    const { user } = Props;

    return (
        <>
            <div className="accmgt_table row m-auto justify-content-center align-items-center">
                <div className="col bg-white">
                    <p>{t("admin:user.firstName")}: {user.firstName}</p>
                    <p>{t("admin:user.lastName")}: {user.lastName}</p>
                    <p>{t("admin:user.email")}: {user.email}</p>
                    <p>{t("admin:user.login")}: {user.username}</p>
                    <p>{t("admin:user.groups")}:</p>
                    <ul>
                        {/* {user.groups.map((item: any, index: number) => {
                            return (
                                <li key={index}>{item.dn}</li>
                            );
                        })} */}
                    </ul>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(Profil);
