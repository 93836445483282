import React from "react";
import { connect } from "react-redux";
import RequestsTable from "../../components/RequestsTable";

function Requests(props: { connected: boolean }) {

    return (<>
        {props.connected && <RequestsTable />}
    </>);

}

const mapStateToProps = (state: any) => {
    return {
        connected: state.user.logged,
    };
};

export default connect(mapStateToProps)(Requests);
