import React from "react";
import { connect } from "react-redux";
import { Authenticate as AuthenticateService } from "../../services/Authenticate";
import { AppState } from "../../store";

class Logout extends React.Component<AppState> {
  public componentWillMount() {
    console.log("logourt");
    AuthenticateService.logout();
  }

}

export default connect()(Logout);
