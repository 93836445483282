import React, { ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import SzSelect from "react-theme-components/build/components/SzSelect/SzSelect";

export interface ISelectFilter {
  options: Array<{label: string, value: number}>;
  handler: any;
  label: string;
  value: {label: string, value: number};
}

const SelectFilter = (props: ISelectFilter): ReactElement => {
  const { t } = useTranslation();

  return (
    <SzSelect
      label={t(props.label)}
      options={props.options}
      value={props.value}
      onChange={(option: {label: string, value: number}) => {props.handler(option); }}
    />
  );
};

export default SelectFilter;
