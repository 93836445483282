import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { SzModal } from "react-theme-components";
import "./errorMessage.scss";

const ErrorMessage = () => {
  const { t } = useTranslation();
  const handleClose = () => {
    window.location.reload();
  };
  return (
    <>
    <SzModal title={t("error.title")} show={true} handleClose={handleClose}>
        <Trans i18nKey="error.text">
          Error, please contact administrator
        </Trans>
      </SzModal>
    </>
  );
};

export default ErrorMessage;
