import React from "react";
import { connect } from "react-redux";
import GroupsTable from "../../components/GroupsTable";
import { AppState } from "../../store";

class Groups extends React.Component<AppState> {
    public render() {
        return (
            <>
                <GroupsTable />
            </>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        connected: state.user.logged,
    };
};

export default connect(mapStateToProps)(Groups);
