import { APP, Constants, ERROR } from "./constants";

export enum requestStatuses {
    APPROVED = "APPROVED",
    DENIED = "DENIED",
    PENDING = "PENDING",
}

export enum userStatuses {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
}

export enum civilities {
    M = "M",
    MME = "MME",
}

export interface IRequest {
  id?: string;
  civility?: keyof typeof civilities;
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  requestReason?: string;
  requestStatus?: keyof typeof requestStatuses;
  phoneNumber?: string;
  createdAt?: Date | {timestamp: number} | string;
  updatedAt?: Date | {timestamp: number} | string;
}

export interface IUser {
    id?: string;
    civility?: keyof typeof civilities;
    username?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    requestReason?: string;
    requestStatus?: keyof typeof requestStatuses;
    userStatus?: string[];
    phoneNumber?: string;
    createdAt?: Date | {timestamp: number} | string;
    updatedAt?: Date | {timestamp: number} | string;
    authenticationToken?: string;
    logged?: boolean;
    actions?: string[] | string;
    isAdmin?: boolean;
    groups?: Array<{
      dn: string,
      gidNumber: number,
      description: string,
    }>;
}

export interface IGroup {
    dn: string;
    description: string;
    gidNumber: string;

}

export interface IAdministratorData {
    requests?: {
      objects: IRequest[]
      totalItem: number,
      currentPage: number,
      totalPage: number,
    };
    users: {
      objects: IUser[]
      totalItem: number,
      currentPage: number,
      totalPage: number,
      keywords: string,
      group: number,
    };
    groups?: {
      objects: IGroup[]
      totalItem: number,
      currentPage: number,
      totalPage: number,
    };
}

export interface IError {
  message: string;
  status: number | null;
  form: { messages: string[] };
}

// Interfaces Actions
export interface ILoginAction {
  type: typeof Constants.LOG_IN;
  payload: IUser;
}

export interface ILogoutAction {
  type: typeof Constants.LOG_OUT;
  payload?: IUser;
}

export interface IGetUsersAdminAction {
    type: typeof Constants.RETRIEVE_USERS;
    payload: {
      data: IUser[],
      keywords: string;
      group: number;
      headers: any,
    };
}

export interface IGetRequestsAdminAction {
  type: typeof Constants.RETRIEVE_REQUESTS;
  payload: {
    data: IRequest[],
    headers: any,
  };
}

export interface IGetGroupsAdminAction {
  type: typeof Constants.RETRIEVE_GROUPS;
  payload: {
    data: IGroup[],
    headers: any,
  };
}

export interface IErrorAction {
  type: typeof ERROR.SET;
  payload: IError;
}
export interface IErrorFormAction {
  type: typeof ERROR.FORM.SET;
  payload: IError;
}
export interface IResetErrorsAction {
  type: typeof ERROR.RESET;
}

export interface IResetUsersFilterAction {
  type: typeof Constants.RESET_USERS_FILTER;
}

export interface ITokenValidAction {
  type: typeof Constants.TOKEN_VALID;
  token_valid: IUser;
}

export interface ISignupAction {
  type: typeof Constants.SIGNUP;
  token_valid: IUser;
}

export interface ILoadingAction {
  type: typeof APP.LOADING.START;
  payload: { loading: boolean };
}

export type ErrorsActionTypes = IErrorAction | IErrorFormAction | IResetErrorsAction;

export type UserActionTypes = ILoginAction | ILogoutAction | ITokenValidAction | ISignupAction;

export type AppActionTypes = ILoadingAction;

export type AdminActionsTypes =
| IGetUsersAdminAction
| IGetGroupsAdminAction
| IGetRequestsAdminAction
| IResetUsersFilterAction;

export type AppActions = UserActionTypes | ErrorsActionTypes | AppActionTypes | AdminActionsTypes;
