import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import SzButton from "react-theme-components/build/components/SzButton/SzButton";

const ValidateFilters = (props: {filterHandler: () => void, resetHandler: () => void}): ReactElement => {

  const { t } = useTranslation();

  return (
    <>
      <SzButton
        icon=""
        onClick={props.filterHandler}
      >
        {t("admin:filter")}
      </SzButton>
      <SzButton
        icon=""
        onClick={props.resetHandler}
      >
        {t("admin:reset")}
      </SzButton>
      </>
  );
};

export default ValidateFilters;
