import { ERROR } from "../constants";
import { IError } from "../types";

const initialState: IError = {
  message: "",
  status: null,
  form: {
    messages: [],
  },
};

const errorReducer = (state = initialState, action: any): IError => {
  switch (action.type) {
    case ERROR.SET:
      return { ...state, message: action.payload.message, status: action.payload.status };
    case ERROR.FORM.SET:
      return { ...state, form: { messages: action.payload.form.messages } };
    case ERROR.RESET:
      return initialState;
    default:
      return state;
  }
};

export default errorReducer;
