import { Formik } from "formik";
import React, { RefObject, useState } from "react";
import { Form } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { SzAlert, SzButton, SzChooseButton, SzInput } from "react-theme-components";
import { bindActionCreators } from "redux";
import { config } from "../config";
import { getFormatedForm, SignupModel } from "../Models";
import { IInput, IInputChoice, IRecaptach } from "../Models/Signup";
import { SignupActions as actions } from "../store/redux/actions";

const recaptchaRef: RefObject<ReCAPTCHA> = React.createRef();

function SignUpForm(props: any) {
  const { error, resetError, signup, errorsApi } = props;
  const [showError, setShowError] = useState(false);
  const [schema, initialValues] = getFormatedForm(SignupModel);
  return (
    <Formik
      validationSchema={schema}
      onSubmit={signup}
      initialValues={initialValues}
      validateOnBlur
      validateOnChange={false}
    >
      {({ handleSubmit, handleChange, handleBlur, values, errors, setFieldValue, touched }) => (
        <form
          onSubmit={(e: any) => {
            handleSubmit(e);
            setShowError(true);
          }}
          className="position-relative"
        >
          {showError && Object.keys(errors).length > 0 && (
            <SzAlert type="danger">{errors[Object.keys(errors)[0]]}</SzAlert>
          )}
          {Object.keys(errorsApi.messages).length > 0 &&
            Object.values(errorsApi.messages).map((error: any) => (
              <SzAlert key="error" type="danger">
                {error}
              </SzAlert>
            ))}
          {Object.keys(SignupModel).map((name: any) => {
            const propSignupForm: keyof typeof SignupModel = name;
            const val: IInputChoice | IInput | IRecaptach = SignupModel[propSignupForm];
            const input = val as IInput;
            if (name === "civility") {
              const input: IInputChoice = val as IInputChoice;
              return (
                <SzChooseButton
                  key={name}
                  className="gaia-inscription-form__button-group d-flex justify-content-between"
                  onChange={handleChange}
                  choices={input.values}
                  name={name}
                  value={values[name]}
                  onBlur={handleBlur}
                />
              );
            }
            if (name === "recaptcha") {
              const input: IRecaptach = val as IRecaptach;
              return (
                <div className="container-recaptcha position-absolute">
                  <ReCAPTCHA
                    sitekey={input.sitekey as string}
                    stoken={input.sitekey as string}
                    ref={recaptchaRef}
                    size="compact"
                    hl="fr"
                    onChange={(e: any) => {
                      setFieldValue("recaptcha", e);
                    }}
                    badge="bottomleft"
                  />
                </div>
              );
            }

            if (name === "requestReason") {
              return (
                <Form.Group controlId={name} key={name}>
                  <Form.Label>{input.label}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder={input.placeholder}
                    name={name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values[name]}
                  />
                </Form.Group>
              );
            }
            return (
              <SzInput
                className={`gaia-inscription-form__form-group d-block d-md-inline-block ${input.className}`}
                key={name}
                label={input.label}
                name={name}
                icon={input.icon}
                required
                valid={!errors[name] && touched[name]}
                error={touched[name] && errors[name]}
                value={values[name]}
                onChange={handleChange}
                onBlur={(e: any) => {
                  handleBlur(e);
                  if (error) {
                    resetError();
                  }
                  if (showError) {
                    setShowError(false);
                  }
                }}
                placeholder={input.placeholder}
                type={input.type}
              />
            );
          })}
          <div className="gaia-inscription-form__validate mt-1 m-auto">
            <div className="row justify-content-center">
              <SzButton className="col-9 col-md-3 m-0" type="submit" isDisabled={!schema.isValidSync(values)}>
                Envoyer
              </SzButton>
            </div>
            <p className="text-center mt-2">
              <a href={`${config.apiUrl}/saml/login`}>Annuler</a>
            </p>
          </div>
        </form>
      )}
    </Formik>
  );
}

const mapStateToProps = (state: any) => {
  return {
    errorsApi: state.error.form,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
