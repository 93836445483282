import { Constants } from "../constants";
import { IUser, UserActionTypes } from "../types";

const userReducerDefaultState: IUser = {
  authenticationToken: "",
  email: "",
  logged: false,
};

const userReducer = (state = userReducerDefaultState, action: UserActionTypes): IUser => {
  switch (action.type) {
    case Constants.LOG_IN:
      return {
        ...state,
        authenticationToken: action.payload.authenticationToken,
        email: action.payload.email,
        logged: action.payload.logged,
        id: action.payload.id,
        civility: action.payload.civility,
        username: action.payload.username,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        groups: action.payload.groups,
        isAdmin: action.payload.isAdmin,
      };
    case Constants.LOG_OUT:
      return { ...state };
    default:
      return state;
  }
};

export default userReducer;
