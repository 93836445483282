import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import SzInput from "react-theme-components/build/components/SzInput/SzInput";

const InputFilter = (
  props: { keyword: string, handler: (s: string) => void, searchHandler: () => void, onKeyUp: (event: any) => void},
  ): ReactElement => {

  const { t } = useTranslation();

  return (
  <SzInput
    label={t("admin:search")}
    type={"text"}
    value={props.keyword}
    onChange={(event: any) => {props.handler(event.target.value); }}
    onKeyUp={props.onKeyUp}
    placeholder=""
  />
);
};

export default InputFilter;
