import { config } from "../config";
import { LOCAL_ROUTES } from "../config/routes";
import { Token as TokenService } from "./Token";

export const unsecuredRoutes: string[] = [LOCAL_ROUTES.SIGN_UP, LOCAL_ROUTES.THANKS];

export class Authenticate {
  public static intialize() {
    const route = window.location.pathname;
    if (!unsecuredRoutes.includes(route)) {
      TokenService.createGaiaTokens();
      TokenService.checkJWTValidityFromAPI();
    }
  }

  public static login() {
    const route = window.location.pathname;
    if (!unsecuredRoutes.includes(route)) {
      return this.loginPath();
    }
  }

  public static logout() {
    sessionStorage.clear();
    window.location = `${config.apiUrl}/saml/logout` as any;
  }

  public static loginPath() {
    return (window.location = `${config.apiUrl}/saml/login` as any);
  }
}
