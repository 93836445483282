import { API_ROUTES } from "../config/routes";
import { civilities, requestStatuses } from "../store/redux/types";
import Request from "./Request";

export class Admin {

  public static getRequestListPromise(
    keywords?: string, itemsPerPage?: number, currentPage?: number, selectOption?: number,
  ) {
    const queryString = this.queryStringFromParams(keywords, itemsPerPage, currentPage, selectOption);
    return Request.get(`${API_ROUTES.REQUEST}${queryString}`)
      .then((res: any) => ({ data: this.formatRequest(res.data), headers: res.headers}));
  }

  public static getGroupsListPromise(
    keywords?: string, itemsPerPage?: number, currentPage?: number, selectOption?: number,
  ) {
    const queryString = this.queryStringFromParams(keywords, itemsPerPage, currentPage, selectOption);
    return Request.get(`${API_ROUTES.GROUP}${queryString}`)
      .then((res: any) => ({data: this.flattenObject(res.data), headers: res.headers}));
  }

  public static getUsersListPromise(
    keywords?: string, selectOption?: number, itemsPerPage?: number, currentPage?: number,
  ) {
    const queryString = this.queryStringFromParams(keywords, itemsPerPage, currentPage, selectOption);
    return Request.get(`${API_ROUTES.USER}${queryString}`)
      .then((res: any) => ({ data: this.flattenObject(res.data), headers: res.headers, keywords, group: selectOption}));
  }

  public static getGroupsAsOptions(): Promise<Array<{label: string, value: number}>> {
    return this.getGroupsListPromise("", 9999, 1)
      .then((res: any) => {
        return res.data.map((row: any) => {
          return {label: row.description, value: row.gidNumber};
        });
      });
  }

  private static formatRequest(requests: any) {
    for (const request of requests) {
      request.civility = request.civility ? civilities[request.civility] : undefined;
      request.requestStatus = request.requestStatus ? requestStatuses[request.requestStatus] : undefined;
      request.createdAt =
        !(request.createdAt instanceof Date)
        && typeof request.createdAt === "object"
        && request.createdAt.timestamp ?
          new Date(request.createdAt.timestamp * 1000) : undefined;
      request.updatedAt =
        !(request.updatedAt instanceof Date)
        && typeof request.updatedAt === "object"
        && request.updatedAt.timestamp ?
          new Date(request.updatedAt.timestamp * 1000) : undefined;
    }
    return requests;
  }

  private static flattenObject(data: any) {
    if (data.length > 0) {
      for (const objectKey in data) {
        if (objectKey !== "prototype") {
          for (const attributeKey in data[objectKey].attributes) {
            if (attributeKey !== "prototype") {
              data[objectKey][attributeKey] = data[objectKey].attributes[attributeKey];
            }
          }
          data[objectKey].attributes = [];
        }
      }
    }
    return data;
  }

  private static queryStringFromParams(
    keywords?: string, itemsPerPage?: number, currentPage?: number, selectOption?: number,
  ): string {
    keywords = keywords ? keywords : "";
    itemsPerPage = itemsPerPage ? itemsPerPage : 0;
    currentPage = currentPage ? currentPage : 0;
    selectOption = selectOption ? selectOption : 0;

    const parameters = [
      `${keywords ? "keywords=" + encodeURI(keywords) : ""}`,
      `${itemsPerPage !== 0 ? "itemsPerPage=" + itemsPerPage : ""}`,
      `${currentPage !== 0 ? "currentPage=" + currentPage : ""}`,
      `${selectOption !== 0 ? "selectOption=" + selectOption : ""}`,
    ];
    return "?" + parameters.reduce((accumulator: string, parameter: string) => {
      if (!accumulator || accumulator.length === 0) {
        return  parameter;
      }
      return `${accumulator}&${parameter}`;
    });
  }
}
