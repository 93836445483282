import { mdiLeadPencil, mdiToggleSwitch, mdiToggleSwitchOffOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React, { ReactElement, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import UserForm from "../Forms/userForm";
import { Admin as AdminService } from "../services/Admin";
import { AdminActions } from "../store/redux/actions";
import { IGroup, IUser, userStatuses } from "../store/redux/types";
import AccmgtTable, { defaultSelectOption } from "./Utils/AccmgtTable/AccmgtTable";

interface IUserTable {
  users: {
    objects: IUser[];
    totalItem: number;
    totalPage: number;
    currentPage: number;
  };
  getUsers: () => void;
  resetUsersFilters: () => void;
  changeUserStatus: (email: string, status: boolean) => void;
}

const UsersTable = (props: IUserTable): ReactElement => {
  const { t } = useTranslation();
  const { changeUserStatus } = props;
  const [UserFormIsActive, setUserFormIsActive] = useState(false);
  const [Entry, setEntry] = useState();
  const [groupOptions, setGroupOptions] = useState([defaultSelectOption]);

  useEffect(() => {
    AdminService.getGroupsAsOptions().then((options: Array<{ label: string; value: number }>) => {
      setGroupOptions([defaultSelectOption].concat(options));
    });
  }, []);

  useEffect(() => { }, [props.users]);

  const usersGroupsCellFormatter = (cell: string, row: { userGroups: IGroup[] }) => {
    if (row && row.userGroups) {
      return row.userGroups.map((group: IGroup) => (
        <>
          {group.description}
          <br />
        </>
      ));
    }
  };

  const tableHeader = [
    {
      dataField: "createdAt",
      formatter: dateCellFormatter,
      text: t("admin:user.createdAt"),
      type: "date",
    },
    {
      dataField: "givenName",
      text: t("admin:user.firstName"),
    },
    {
      dataField: "sn",
      text: t("admin:user.lastName"),
    },
    {
      dataField: "mail",
      text: t("admin:user.email"),
    },
    {
      dataField: "userStatus",
      text: t("admin:user.status.label"),
      formatter: userStatusCellFormatter,
    },
    {
      dataField: "usersGroups",
      text: t("admin:user.groups"),
      formatter: usersGroupsCellFormatter,
    },
    {
      dataField: "actions",
      text: t("admin:user.actions"),
      formatter: actionsCellFormatter,
    },
  ];

  function userStatusCellFormatter(cell: keyof typeof userStatuses, row: IUser) {
    switch (cell[0]) {
      case userStatuses.BLOCKED:
        return (
          <OverlayTrigger
            placement={"left"}
            overlay={
              <Tooltip id={`tooltip-left`}>
                Compte désactivé
              </Tooltip>
            }
          >
            <div onClick={() => switchUserStatus(row, true)} >
              <Icon className={"clickable icon-toggle-switch-off-outline"} path={mdiToggleSwitchOffOutline} size={1}/>
            </div>
          </OverlayTrigger>
        );
      case userStatuses.ACTIVE:
      default:
        return (
          <OverlayTrigger
            placement={"left"}
            overlay={
              <Tooltip id={`tooltip-left`}>
                Compte actif
              </Tooltip>
            }
          >
            <div onClick={() => switchUserStatus(row, false)} >
              <Icon className={"clickable icon-toggle-switch"} path={mdiToggleSwitch} size={1}/>
            </div>
          </OverlayTrigger>
        );

    }
  }

  function dateCellFormatter(cell: string) {
    const date = new Date(Date.parse(cell[0]));
    return <>{date.toLocaleDateString()}</>;
  }

  const switchUserStatus = (user: any, userStatus: boolean): void => {
    changeUserStatus(user.mail[0], userStatus);
  };

  const showUserForm = (user: any) => {
    setEntry(user);
    setUserFormIsActive(true);
  };

  const handleCloseUserForm = () => {
    setUserFormIsActive(false);
  };

  function actionsCellFormatter(cell: never, row: any) {
    switch (row.userStatus[0]) {
      case userStatuses.BLOCKED:
        return (
          <OverlayTrigger
            placement={"left"}
            overlay={
              <Tooltip id={`tooltip-left`}>
                Modifier le compte utilisateur
              </Tooltip>
            }
          >
            <div onClick={() => showUserForm(row)} >
              <Icon className={"clickable icon-lead-pencil"} path={mdiLeadPencil} size={1}/>
            </div>
          </OverlayTrigger>
        );
      case userStatuses.ACTIVE:
      default:
        return (
          <OverlayTrigger
            placement={"left"}
            overlay={
              <Tooltip id={`tooltip-left`}>
                Modifier le compte utilisateur
              </Tooltip>
            }
          >
            <div onClick={() => showUserForm(row)} >
              <Icon className={"clickable icon-lead-pencil"} path={mdiLeadPencil} size={1}/>
            </div>
          </OverlayTrigger>
        );
    }
  }

  return (
    <>
      {UserFormIsActive && (
        <UserForm user={Entry} visible={UserFormIsActive} handleCloseUserForm={handleCloseUserForm} />
      )}
      <AccmgtTable
        className={"users-table"}
        data={props.users}
        header={tableHeader}
        keyField={"mail"}
        updateHandler={props.getUsers}
        resetHandler={props.resetUsersFilters}
        filters={{
          hasInput: true,
          inputFilteredFields: ["firstName", "lastName", "email"],
          selectOptions: groupOptions,
          selectLabel: "admin:group.label",
          displayValidate: true,
        }}
      />
    </>
  );
};

const mapStateToProps = (state: {
  admin: {
    users: {
      objects: IUser[];
      totalItem: number;
      totalPage: number;
      currentPage: number;
    };
  };
}) => {
  return {
    users: state.admin.users,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(AdminActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable);
