import { mdiAccount, mdiLogout, mdiMenu } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { connect } from "react-redux";
import { SzDropDown, SzHeader, SzIcon } from "react-theme-components";
import { bindActionCreators } from "redux";
import userIcon from "../../assets/img/user-icon.png";
import { LOCAL_ROUTES } from "../../config/routes";
import { UserActions } from "../../store/redux/actions";
import "./Header.scss";

interface IHeadersProps {
    handleOnClick: (event: React.MouseEvent<HTMLDivElement>) => void;
    connected: boolean;
    logout: any;
  }

function Header(Props: IHeadersProps) {
    const { handleOnClick, connected, logout } = Props;

    const menuOpt = [
        {
            content: "PROFIL",
            link: LOCAL_ROUTES.PROFILE,
            icon: "mdi mdi-account",
            iconPath: mdiAccount,
        },
        {
            content: "DÉCONNEXION",
            handleClick: () => logout(),
            icon: "mdi mdi-logout",
            iconPath: mdiLogout,
        },
    ];

    const menuImage = (
      <OverlayTrigger
        key={"user_menu"}
        placement={"bottom"}
        overlay={
            <Tooltip id={`tooltip-bottom`}>
                Mon Compte
            </Tooltip>
        }
      >
          <img src={userIcon} alt={"user icon"} height={30} />
      </OverlayTrigger>
    );

    const userMenu = (

        <SzDropDown image={menuImage} className="pr-4">
            {menuOpt.map((item: any, index: number) => {
                return (
                    <a
                        href={item.link || "#"}
                        className="border-bottom font-weight-bold justify-content-space-around
                        d-flex align-items-center w-auto py-2"
                        key={index}
                        onClick={item.handleClick}
                    >
                        {item.icon && <Icon size={0.8} path={item.iconPath}/> }
                        {item.content}
                    </a>
                );
            })}
        </SzDropDown>
    );
    if (connected) {
    return (
        <>
            <SzHeader className="gaia-header d-flex align-items-stretch justify-content-between">
                <div
                    className="gaia-header__menu-icon text-secondary align-items-center border-right"
                    onClick={handleOnClick}
                >
                    <Icon path={mdiMenu} size={1.5} />
                    {/*<i className="sz-navigation-menu" />*/}
                </div>
                <h1 className="gaia-header__brand-title px-3">Gestionnaire de comptes</h1>
                <div className="gaia-header signin d-flex align-items-center ml-auto
                justify-content-around text-uppercase font-weight-bold px-5 border-left">
                    {userMenu}
                </div>
            </SzHeader>
        </>
    );
    }
    return (
        <SzHeader className="gaia-header unlogged d-flex align-items-stretch ">
            <h1 className="gaia-header__brand-title px-3">Gestionnaire de comptes</h1>
        </SzHeader>
      );

}

const mapStateToProps = (state: any) => {
    return {
        connected: state.user.logged,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(UserActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
