  import { mdiEmail, mdiHumanFemale, mdiHumanMale, mdiTimerSand } from "@mdi/js";
  import Icon from "@mdi/react";
  import React from "react";
  import * as Yup from "yup";
  import { config } from "../config";

  const phoneRegExp = /^((0|\+33(0)?){1}[1-7]{1}[0-9]{8})?$/;

  export enum IFormSignup {
    civility,
    email,
    firstName,
    lastName,
    repeated_email,
    requestReason,
  }
  export interface IInputChoice {
    schema: Yup.StringSchema<string>;
    values: Array<{
      icon: string;
      label: string;
      value: string;
    }>;
  }

  export interface IInput {
    className?: string;
    icon?: string;
    label: string;
    placeholder?: string;
    schema?: Yup.StringSchema<any>;
    type: string;
  }
  export interface IRecaptach {
    sitekey?: string;
    stokenkey?: string;
    schema?: Yup.StringSchema<string>;
  }

  const IconM =  <Icon className={"icon-timer-sand"} path={mdiHumanMale} size={1}/>;
  const IconMME =  <Icon className={"icon-timer-sand"} path={mdiHumanFemale} size={1}/>;
  const IconEmail =  <Icon className={"icon-timer-sand"} path={mdiEmail} size={1}/>;

  export default {
    civility: {
      schema: Yup.string()
        .matches(/(M|MME)/, "Le genre sélectionné n'est pas disponible.")
        .required("Veuillez sélectionner un genre."),
      values: [
        {
          icon: IconMME,
          label: "Madame",
          value: "MME",
        },
        {
          icon: IconM,
          label: "Monsieur",
          value: "M",
        },
      ],
    },
    firstName: {
      className: "pr-md-3",
      label: "Prénom",
      placeholder: "Saisir votre prénom",
      schema: Yup.string()
        .min(2, "Le champ prénom doit être rempli.")
        .required("Le champ prénom doit être rempli."),
      type: "text",
    },
    lastName: {
      className: "pl-md-3",
      label: "Nom",
      placeholder: "Saisir votre nom",
      schema: Yup.string()
        .min(2, "Le champ nom doit être rempli.")
        .max(50, "Too Long!")
        .required("Le champ nom doit être rempli."),
      type: "text",
    },
    phoneNumber: {
      className: "pr-md-3 block",
      label: "Numéro de téléphone",
      placeholder: "Saisir votre numéro de téléphone",
      schema: Yup.string()
        .matches(phoneRegExp, "Numéro de téléphone invalide")
        .required("Le champ nom doit être rempli."),
      type: "text",
    },
    email: {
      className: "pr-md-3",
      icon: IconEmail,
      label: "E-mail",
      placeholder: "Saisir votre e-mail",
      schema: Yup.string()
        .email("Le champ mail n'est pas valide.")
        .required("Le champ mail doit être rempli."),
      type: "email",
    },
    repeated_email: {
      className: "pl-md-3",
      icon: IconEmail,
      label: "Confirmation E-mail",
      placeholder: "Saisir à nouveau votre e-mail",
      schema: Yup.string()
        .test("email", "Les champs mail doivent être identique.", function(value) {
          const { email } = this.parent;
          return email === value;
        })
        .required("Le champ mail doit être rempli."),
      type: "email",
    },
    requestReason: {
      label: "Motif de la demande",
      placeholder: "Saisir le motif de la demande d'habilitation",
      type: "textarea",
    },
    recaptcha: {
      schema: Yup.string().required("Veuillez renseigner le captcha"),
      sitekey: config.recaptchaKey,
      stokenkey: config.recaptchaStoken,
    },
  };
