import { store } from "../..";
import { ERROR } from "../constants";
import { IErrorAction, IErrorFormAction, IResetErrorsAction } from "../types";

export default {
  setErrorMessage(message: string, status: number): IErrorAction {
    return {
      payload: { ...store.getState().error, message, status },
      type: ERROR.SET,
    };
  },
  setFormErrors(messages: string[]): IErrorFormAction {
    return {
      payload: { ...store.getState().error, form: { messages } },
      type: ERROR.FORM.SET,
    };
  },
  resetErrors(): IResetErrorsAction {
    return {
      type: ERROR.RESET,
    };
  },
};
