import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import adminReducer from "./admin";
import appReducer from "./app";
import errorReducer from "./errors";
import userReducer from "./user";

const rootReducer = (history: History) =>
  combineReducers({
    app: appReducer,
    error: errorReducer,
    router: connectRouter(history),
    user: userReducer,
    admin: adminReducer,
  });

export default rootReducer;
