import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Authenticate as AuthenticateService } from "../../services/Authenticate";
import { ErrorsActions as actions } from "../../store/redux/actions";
import "./thanks.scss";

function Thanks(props: any) {
  const { resetErrors } = props;
  const { t } = useTranslation();
  const handleClick = () => {
    resetErrors();
    AuthenticateService.loginPath();
  };
  return (
    <div className="gaia-form py-4 m-auto">
      <h5 className="light">{t("authorization.request")}</h5>
      <div className="sz-body-stepper bg-white rounded">
        <p>{t("authorization.text")}</p>
        <p>{t("authorization.textSecond")}</p>
        <p className="text-center mt-2">
          <a href="#" onClick={handleClick}>
            {t("back")}
          </a>
        </p>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(actions, dispatch),
});

export default connect(null, mapDispatchToProps)(Thanks);
