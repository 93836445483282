declare global {
    interface Window {
        REACT_APP_API_HOST: string;
        REACT_APP_RECAPTCHA_KEY: string;
        REACT_APP_RECAPTCHA_STOKEN: string;
    }
}

// use process.env in dev instead window
export const config = {
    apiUrl: window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST,
    host: window.location.origin,
    recaptchaKey: window.REACT_APP_RECAPTCHA_KEY,
    recaptchaStoken: window.REACT_APP_RECAPTCHA_STOKEN,
};
