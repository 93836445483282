  import * as Yup from "yup";

  export enum IFormModifyGroup {
    description,
    gidNumber,
    dn,
  }

  export interface IInput {
    className?: string;
    icon?: string;
    label: string;
    disabled?: string;
    placeholder?: string;
    schema?: Yup.StringSchema<any>;
    type: string;
  }

  export default {
    description: {
      className: "pr-md-3",
      label: "Nom",
      placeholder: "Saisir la description",
      schema: Yup.string()
        .min(2, "Le champ Nom doit être rempli.")
        .required("Le champ Nom doit être rempli."),
      type: "text",
    },
  };
