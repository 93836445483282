import { mdiLeadPencil } from "@mdi/js";
import Icon from "@mdi/react";
import React, { ReactElement, useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import GroupForm from "../Forms/groupForm";
import { AdminActions } from "../store/redux/actions";
import { IGroup, requestStatuses } from "../store/redux/types";
import AccmgtTable from "./Utils/AccmgtTable/AccmgtTable";

interface IGroupTable {
  groups: {
    objects: IGroup[];
    totalItem: number;
    totalPage: number;
    currentPage: number;
  };
  getGroups: () => void;
}

const GroupsTable = (props: IGroupTable): ReactElement => {
  const { t } = useTranslation();
  const [GroupFormIsActive, setGroupFormIsActive] = useState(false);
  const [Entry, setEntry] = useState();

  useEffect(() => {}, [props.groups]);

  const tableHeader = [
    {
      dataField: "description",
      text: t("admin:group.groupName"),
    },
    {
      dataField: "dn",
      text: t("admin:group.domainName"),
    },
    {
      dataField: "member",
      formatter: memberCountCellFormatter,
      text: t("admin:group.userCount"),
    },
    {
      dataField: "gidNumber",
      text: t("admin:group.actions"),
      formatter: actionsCellFormatter,
    },
  ];

  const showGroupForm = (group: any) => {
    setEntry(group);
    setGroupFormIsActive(true);
  };

  const handleCloseGroupForm = () => {
    setGroupFormIsActive(false);
  };

  function actionsCellFormatter(cell: any, row: { id: string; requestStatus: keyof typeof requestStatuses }) {
    return (
      <OverlayTrigger
        placement={"left"}
        overlay={
          <Tooltip id={`tooltip-left`}>
            Modifier le groupe
          </Tooltip>
        }
      >
        <div onClick={() => showGroupForm(row)} >
          <Icon className={"clickable icon-lead-pencil"} path={mdiLeadPencil} size={1}/>
        </div>
      </OverlayTrigger>
    );
  }

  function memberCountCellFormatter(cell: string[]) {
    return cell.length - 1 >= 0 ? cell.length - 1 : 0;
  }

  return (
    <>
      {GroupFormIsActive && (
        <GroupForm group={Entry} visible={GroupFormIsActive} handleCloseUserForm={handleCloseGroupForm} />
      )}
      <AccmgtTable
        className={"groups-table"}
        data={props.groups}
        header={tableHeader}
        keyField={"gidNumber"}
        updateHandler={props.getGroups}
        filters={{
          hasInput: true,
          inputFilteredFields: ["name", "dn"],
          displayValidate: true,
        }}
      />
    </>
  );
};

const mapStateToProps = (state: {
  admin: {
    groups: {
      objects: IGroup[];
      totalItem: number;
      totalPage: number;
      currentPage: number;
    };
  };
}) => {
  return {
    groups: state.admin.groups,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(AdminActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupsTable);
