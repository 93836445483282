import React, { ReactElement, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { defaultSelectOption } from "../AccmgtTable";
import "./Filters.scss";
import InputFilter from "./InputFilter";
import SelectFilter from "./SelectFilter";
import ValidateFilters from "./ValidateFilters";

export interface IFilters {
  hasInput?: boolean;
  inputFilteredFields?: string[];
  selectOptions?: Array<{ label: string; value: number }>;
  selectLabel?: string;
  displayValidate?: boolean;
}

interface IFilter {
  filters: IFilters;
  data: object[];
  inputHandler: (s: string) => void;
  selectHandler: (n: {label: string, value: number}) => void;
  resetHandler?: () => void;
}

const ENTER_KEY = 13;

const Filters = (props: IFilter): ReactElement => {
  const [searchKeyword, setSearchKeyword]: [string, (s: string) => void] = useState("");
  const [selectedOption, setSelectedOption] = useState(defaultSelectOption);
  const [displaySelect, setDisplaySelect]: [boolean, (b: boolean) => void]
    = useState(!!(props.filters.selectOptions && props.filters.selectLabel));

  let md: number;
  let offset: number;
  switch (
    0 +
    (props.filters.hasInput ? 1 : 0) +
    (props.filters.selectOptions && props.filters.selectLabel ? 1 : 0) +
    (props.filters.displayValidate ? 1 : 0)
  ) {
    case 2:
      md = 5;
      offset = 2;
      break;
    case 3:
      md = 3;
      offset = 1;
      break;
    case 1:
    default:
      md = 12;
      offset = 0;
      break;
  }

  function filter(): void {
    if ("undefined" !== typeof props.inputHandler) {
      props.inputHandler(searchKeyword);
    }
    if ("undefined" !== typeof props.selectHandler) {
      props.selectHandler(selectedOption);
    }
  }

  function reset(): void {
    if ("undefined" !== typeof props.inputHandler) {
      setSearchKeyword("");
      props.inputHandler("");
    }
    if ("undefined" !== typeof props.selectHandler) {
      setDisplaySelect(false);
      setSelectedOption({label: "", value: 0});
      props.selectHandler({label: "", value: 0});
      setTimeout(() => {
        setDisplaySelect(true);
      }, 1);
    }
    if ("undefined" !== typeof props.resetHandler) {
      props.resetHandler();
    }
  }

  const onKeyUp = (event: any) => {
    switch (event.keyCode) {
      case ENTER_KEY: {
        filter();
        break;
      }
    }
  };

  return (
    <Row className={"accmgt_table--filters"}>
      {props.filters.hasInput && (
        <Col sm={12} md={{ span: md }}>
          <InputFilter onKeyUp={onKeyUp} keyword={searchKeyword} handler={setSearchKeyword} searchHandler={filter} />
        </Col>
      )}

      {props.filters.selectOptions && props.filters.selectLabel && displaySelect && (
        <Col sm={12} md={{ span: md }}>
          <SelectFilter
            options={props.filters.selectOptions}
            handler={setSelectedOption}
            label={props.filters.selectLabel}
            value={selectedOption}
          />
        </Col>
      )}

      {props.filters.displayValidate && (
        <Col sm={12} md={{ span: md }} className={"accmgt_table--filters__validate"}>
          <ValidateFilters filterHandler={filter} resetHandler={reset}/>
        </Col>
      )}
    </Row>
  );
};

export default Filters;
