import * as Yup from "yup";

export function getFormatedForm(model: any, defaultValues?: undefined) {
  const schema = Yup.object().shape(
    Object.fromEntries(Object.entries(model).map((value: [string, any]) => {
      return [value[0], value[1].schema];
    })) as unknown as Array<Yup.Ref | Yup.Schema<unknown>>,
  );

  const initialValues = Object.fromEntries(Object.entries(model).map((value) => [value[0]]));
  defaultValues && Object.assign(initialValues, defaultValues);
  return [schema, initialValues];
}

export { default as SignupModel } from "./Signup";
export { default as ModifyUser } from "./ModifyUserForm";
export { default as ModifyGroup } from "./ModifyGroupForm";
