import { Authenticate as AuthenticateService } from "../../../services/Authenticate";
import { Constants } from "../constants";
import { AppActions, IUser } from "../types";

export default {
  login(user: IUser): AppActions {
    return {
      payload: { ...user },
      type: Constants.LOG_IN,
    };
  },
  logout(user?: IUser): any {
    AuthenticateService.logout();
    return {
      payload: { ...user },
      type: Constants.LOG_OUT,
    };
  },
};
