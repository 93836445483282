import { AxiosResponse } from "axios";
import jwt from "jsonwebtoken";
import qs from "qs";
import { store } from "../store";
import { Constants } from "../store/redux/constants";
import { Authenticate as AuthenticateService } from "./Authenticate";
import Request from "./Request";

export const isUndefined = (item: string | null) => !item || typeof item === "undefined" || item === "undefined";

export class Token {
  public static createGaiaTokens() {
    let token: string = qs.parse(window.location.search.substring(1)).token as string;
    let refreshToken: string = qs.parse(window.location.search).refresh as string;
    const tokenAccountManagerFromStorage = sessionStorage.getItem("token");
    const refreshTokenFromStorage = sessionStorage.getItem("refreshToken");
    // TODO : checkValidToken()
    if (isUndefined(token) && isUndefined(tokenAccountManagerFromStorage)) {
      AuthenticateService.login();
    } else {

      token = !isUndefined(token)
        ? token
        : !isUndefined(tokenAccountManagerFromStorage)
          ? tokenAccountManagerFromStorage
          : AuthenticateService.login();

      refreshToken = !isUndefined(refreshToken)
        ? refreshToken
        : !isUndefined(refreshTokenFromStorage)
          ? refreshTokenFromStorage as string
          : "";

      this.setSessionsTokens(token, refreshToken);
      this.updateTokensInStore(token);

      window.history.pushState(null, "", window.location.pathname);
    }
  }

  public static checkJWTValidityFromAPI() {
    Request.checkJWT()
      .then((res: AxiosResponse<any>) => {
        if (res.status as number !== 200) {
          AuthenticateService.logout();
        }
      })
      .catch((res: AxiosResponse<any>) => AuthenticateService.logout());
  }

  public static isJwtNotValid() {
    const token = sessionStorage.getItem("token") as string;
    const decodedToken: any = jwt.decode(token, { complete: true });
    // JS timestamp is expressed in milliseconds therefore we truncate it.
    const timestamp = Math.floor(Date.now() / 1000);
    return decodedToken && typeof decodedToken !== "undefined" && decodedToken.payload.exp < timestamp;
  }

  public static async refreshToken() {
    return await Request.refreshToken().then((resf: any) => {
      this.updateTokens(resf.data);
    });
  }

  private static setSessionsTokens(token: string, refreshToken: string) {
    if (!isUndefined(token)) {
      sessionStorage.setItem("token", token);
    }
    if (!isUndefined(refreshToken)) {
      sessionStorage.setItem("refreshToken", refreshToken);
    }
  }

  private static getPayloadFromToken(token: string) {
    const decodedToken: any = jwt.decode(token, { complete: true });
    if (decodedToken && typeof decodedToken !== "undefined") {
      return decodedToken.payload;
    }
    return null;
  }

  private static updateTokens(json: any) {
    if (typeof json.code !== "undefined" && json.code === 401) {
      AuthenticateService.logout();
    }

    if (!isUndefined(json.token)) {
      sessionStorage.setItem("token", json.token);
      this.updateTokensInStore(json.token);

    }
    if (!isUndefined(json.refresh_token)) {
      sessionStorage.setItem("refreshToken", json.refresh_token);
    }
  }

  private static updateTokensInStore(token: string) {
    const tokenPayload = this.getPayloadFromToken(token);
    store.dispatch({
      payload: {
        authenticationToken: token,
        email: tokenPayload.user.attributes.mail[0],
        logged: true,
        id: tokenPayload.user.attributes.uidNumber[0],
        username: tokenPayload.username,
        firstName: tokenPayload.user.attributes.givenName[0],
        lastName: tokenPayload.user.attributes.sn[0],
        isAdmin: tokenPayload.admin,
      },
      type: Constants.LOG_IN,
    });
  }
}
