import { mdiAccount, mdiCheck, mdiClose, mdiTimerSand } from "@mdi/js";
import Icon from "@mdi/react";
import React, { ReactElement } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { AdminActions } from "../store/redux/actions";
import { IRequest, requestStatuses } from "../store/redux/types";
import AccmgtTable from "./Utils/AccmgtTable/AccmgtTable";

interface IRequestsTable {
  requests: {
    objects: IRequest[];
    totalItem: number;
    totalPage: number;
    currentPage: number;
  };
  getRequests: () => void;
  updateStatus: (s: string, b: boolean) => void;
}

const RequestsTable = (props: IRequestsTable): ReactElement => {
  const { t } = useTranslation();
  const tableHeader = [
    {
      dataField: "createdAt",
      formatter: dateCellFormatter,
      text: t("admin:user.createdAt"),
      type: "date",
    },
    {
      dataField: "firstName",
      text: t("admin:user.firstName"),
    },
    {
      dataField: "lastName",
      text: t("admin:user.lastName"),
    },
    {
      dataField: "requestReason",
      text: t("admin:user.requestReason"),
    },
    {
      dataField: "email",
      text: t("admin:user.email"),
    },
    {
      dataField: "updatedAt",
      formatter: dateCellFormatter,
      text: t("admin:user.updatedAt"),
      type: "date",
    },
    {
      dataField: "requestStatus",
      text: t("admin:user.requestStatus"),
      formatter: requestStatusCellFormatter,
    },
    {
      dataField: "actions",
      text: t("admin:user.actions"),
      formatter: actionsCellFormatter,
    },
  ];

  function requestStatusCellFormatter(cell: keyof typeof requestStatuses) {
    switch (cell) {
      case requestStatuses.APPROVED:
        return (
          <OverlayTrigger
            placement={"left"}
            overlay={
              <Tooltip id={`tooltip-left`}>
                Demande d'habilitation validée
              </Tooltip>
            }
          >
            <Icon className={"icon-check"} path={mdiCheck} size={1}/>
          </OverlayTrigger>
        );
      case requestStatuses.DENIED:
        return (
          <OverlayTrigger
            placement={"left"}
            overlay={
              <Tooltip id={`tooltip-left`}>
                Demande d'habilitation refusée
              </Tooltip>
            }
          >
            <Icon className={"icon-close"} path={mdiClose} size={1}/>
          </OverlayTrigger>
        );
      case requestStatuses.PENDING:
      default:
        return (
          <OverlayTrigger
            placement={"left"}
            overlay={
              <Tooltip id={`tooltip-left`}>
                En attente de traitement
              </Tooltip>
            }
          >
            <Icon className={"icon-timer-sand"} path={mdiTimerSand} size={1}/>
          </OverlayTrigger>
        );
    }
  }

  function dateCellFormatter(cell: Date) {
    return <>{cell.toLocaleDateString()}</>;
  }

  function actionsCellFormatter(cell: any, row: { id: string; requestStatus: keyof typeof requestStatuses }) {
    switch (row.requestStatus) {
      case requestStatuses.APPROVED:
        return <Icon path={mdiAccount} size={1}/>;
      case requestStatuses.DENIED:
        return <></>;
      case requestStatuses.PENDING:
      default:
        return (
          <>
            <OverlayTrigger
              placement={"left"}
              overlay={
                <Tooltip id={`tooltip-left`}>
                  Valider la demande d'habilitation
                </Tooltip>
              }
            >
              <div onClick={() => props.updateStatus(row.id, true)}>
                <Icon className={"clickable icon-check"} path={mdiCheck} size={1}/>
              </div>
            </OverlayTrigger>
            <OverlayTrigger
              placement={"left"}
              overlay={
                <Tooltip id={`tooltip-left`}>
                  Refuser la demande d'habilitation
                </Tooltip>
              }
            >
              <div onClick={() => props.updateStatus(row.id, false)}>
                <Icon className={"clickable icon-close"} path={mdiClose} size={1}/>
              </div>
            </OverlayTrigger>
          </>
        );
    }
  }

  return (
    <AccmgtTable
      className={"requests-table"}
      data={props.requests}
      header={tableHeader}
      keyField={"email"}
      updateHandler={props.getRequests}
    />
  );
};

const mapStateToProps = (state: {
  admin: {
    requests: {
      objects: IRequest[];
      totalItem: number;
      totalPage: number;
      currentPage: number;
    };
  };
}) => {
  return {
    requests: state.admin.requests,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(AdminActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestsTable);
