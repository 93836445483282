import React, { ReactElement } from "react";
import BootstrapPagination from "react-bootstrap/Pagination";
import "./Pagination.scss";

interface IPagination {
  totalPage: number;
  currentPage: number;
  handler: (newPage: number) => void;
}

const Pagination = (props: IPagination): ReactElement => {

  const { totalPage, currentPage, handler } = props;

  function handleClick(page: number): void {
    if (page > 0 && page <= totalPage) {
      handler(page);
    }
    return;
  }

  const elementArray: ReactElement[] = [];

  /**
   * This generate pagination body on the following rules :
   *
   * Current page is always displayed and active
   * First, last, previous and next page are always displayed.
   * If there is 12 pages or more, the 2 Previous and Next pages are displayed
   * If there is 8 page or less, all pages are displayed, otherwise ellipsis covers range between non displayed pages
   * Never display 2 ellipsis one after the other.
   *
   */
  let previousIsEllipsis: boolean = false;

  for (let i = 1; i <= totalPage; i++) {
    // Always display current page as active
    if (i === currentPage) {
      elementArray[i] = <BootstrapPagination.Item key={i} active >{i}</BootstrapPagination.Item>;
      previousIsEllipsis = false;
      continue;
    } else {
      // More than 8 page and index isn't first nor last nor prev nor next
      if (totalPage > 8 && [1, currentPage - 1, currentPage + 1, totalPage].indexOf(i) === -1) {
        // Less than 12 (only prev and next are displayed)
        if (totalPage < 12) {
          // If there is already an ellipsis, no need to display another one
          if (!previousIsEllipsis) {
            // If ellipsis will replace a single page, we display the page instead
            if ([2, totalPage - 1].indexOf(i) !== -1 && [currentPage - 2, currentPage + 2].indexOf(i) !== -1) {
              elementArray[i] =
                <BootstrapPagination.Item key={i} onClick={() => handleClick(i)}>{i}</BootstrapPagination.Item>;
              previousIsEllipsis = false;
              continue;
            }
            // Else there is an ellipsis
            previousIsEllipsis = true;
            elementArray[i] = <BootstrapPagination.Ellipsis key={i} disabled/>;
          }
          continue;
          // More than 12 pages : 2 prev and next pages are displayed
          // Page is one of 2 prev or 2 next
        } else if ([currentPage - 2, currentPage + 2].indexOf(i) === -1) {
          // If there is already an ellipsis, no need to display another one
          if (!previousIsEllipsis) {
            // If ellipsis will replace a single page, we display the page instead
            if ([2, totalPage - 1].indexOf(i) !== -1 && [currentPage - 3, currentPage + 3].indexOf(i) !== -1) {
              elementArray[i] =
                <BootstrapPagination.Item key={i} onClick={() => handleClick(i)}>{i}</BootstrapPagination.Item>;
              previousIsEllipsis = false;
              continue;
            }
            // Else there is an ellipsis
            previousIsEllipsis = true;
            elementArray[i] = <BootstrapPagination.Ellipsis key={i} disabled/>;
          }
          continue;
        }
      }
    }
    // If none of the above are triggered, the page is displayed normally.
    elementArray[i] = <BootstrapPagination.Item key={i} onClick={() => handleClick(i)}>{i}</BootstrapPagination.Item>;
    previousIsEllipsis = false;
  }

  const prev = currentPage > 1 ? currentPage - 1 : currentPage;
  const next = currentPage < totalPage ? currentPage + 1 : currentPage;

  return (
    <div className={"accmgt_table--pagination"}>
      <BootstrapPagination size="lg">
        <BootstrapPagination.Prev key="prev" onClick={() => handleClick(prev)} disabled={1 === currentPage}/>
        {
          elementArray.reduce((accumulator: ReactElement, current: ReactElement): ReactElement => {
            return <>{accumulator} {current}</>;
          })
        }
        <BootstrapPagination.Next key="next" onClick={() => handleClick(next)} disabled={totalPage === currentPage} />
      </BootstrapPagination>
    </div>
  );
};

export default Pagination;
