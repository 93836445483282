import { Constants } from "../constants";
import { AdminActionsTypes, IAdministratorData } from "../types";

const adminReducerDefaultState: IAdministratorData = {
  requests: {
    objects: [],
    totalItem: 0,
    totalPage: 0,
    currentPage: 1,
  },
  users: {
    objects: [],
    keywords: "",
    group: 0,
    totalItem: 0,
    totalPage: 0,
    currentPage: 1,
  },
  groups: {
    objects: [],
    totalItem: 0,
    totalPage: 0,
    currentPage: 1,
  },
};
// x-pagination-total-count', 'x-pagination-page-count', 'x-pagination-current-page', 'x-pagination-per-page
const adminReducer = (
  state = adminReducerDefaultState,
  action: AdminActionsTypes,
): IAdministratorData => {
  switch (action.type) {
    case Constants.RETRIEVE_USERS:
      return {
        ...state,
        users: {
          objects: action.payload.data,
          keywords: action.payload.keywords,
          group: action.payload.group,
          totalItem: parseInt(action.payload.headers["x-pagination-total-count"], 10),
          totalPage: parseInt(action.payload.headers["x-pagination-page-count"], 10),
          currentPage: parseInt(action.payload.headers["x-pagination-current-page"], 10),
        },
      };
    case Constants.RESET_USERS_FILTER:
      return {
        ...state,
        users: {
          objects: state.users.objects,
          keywords: "",
          group: 0,
          totalItem: state.users.totalItem,
          totalPage: state.users.totalPage,
          currentPage: 1,
        },
      };
    case Constants.RETRIEVE_REQUESTS:
      return {
        ...state,
        requests: {
          objects: action.payload.data,
          totalItem: parseInt(action.payload.headers["x-pagination-total-count"], 10),
          totalPage: parseInt(action.payload.headers["x-pagination-page-count"], 10),
          currentPage: parseInt(action.payload.headers["x-pagination-current-page"], 10),
        },
      };
    case Constants.RETRIEVE_GROUPS:
      return{
        ...state,
        groups: {
          objects: action.payload.data,
          totalItem: parseInt(action.payload.headers["x-pagination-total-count"], 10),
          totalPage: parseInt(action.payload.headers["x-pagination-page-count"], 10),
          currentPage: parseInt(action.payload.headers["x-pagination-current-page"], 10),
        },
      };
    default:
      return state;
  }
};

export default adminReducer;
