import { APP } from "../constants";
import { ILoadingAction } from "../types";

export default {
  launchLoading(): ILoadingAction {
    return {
      payload: { loading: true },
      type: APP.LOADING.START,
    };
  },
  stopLoading(): ILoadingAction {
    return {
      payload: { loading: false },
      type: APP.LOADING.STOP,
    };
  },
};
