import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SignUpForm from "../../Forms/Signup";
import "./signup.scss";

function Signup() {
  const { t } = useTranslation();
  return (
    <div className="gaia-form py-4 m-auto">
      <h5 className="light">{t("authorization.request")}</h5>
      <h5 className="sz-underline font-weight-bold text-uppercase sz-normal-font-size light">
        {t("authorization.informationTitle")}
      </h5>
      <div className="sz-body-stepper bg-white rounded">
        <SignUpForm />
      </div>
    </div>
  );
}

export default connect()(Signup);
