  import * as Yup from "yup";

  export enum IFormModifyUser {
    firstName,
    lastName,
  }

  export interface IInput {
    className?: string;
    icon?: string;
    label: string;
    disabled?: string;
    placeholder?: string;
    schema?: Yup.StringSchema<any>;
    type: string;
  }

  export default {
    firstName: {
      className: "pr-md-3",
      label: "Prénom",
      placeholder: "Saisir votre prénom",
      schema: Yup.string()
        .min(2, "Le champ prénom doit être rempli.")
        .required("Le champ prénom doit être rempli."),
      type: "text",
    },
    lastName: {
      className: "pl-md-3",
      label: "Nom",
      placeholder: "Saisir votre nom",
      schema: Yup.string()
        .min(2, "Le champ nom doit être rempli.")
        .max(50, "Too Long!")
        .required("Le champ nom doit être rempli."),
      type: "text",
    },
  };
